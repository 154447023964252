import React from "react";
import threethousand from "../../images/3000.svg";
import five from "../../images/50.svg";
import onehundred from "../../images/100.svg";
import fiveteen from "../../images/15.svg";
import plus from "../../images/+.svg";

const Whoarewe = ({ data, isMobileView }) => {
  return (
    <div className="ouraprchSec p-md-5">
      <div className="reviewContainer">
        <div className="detailSection">
          <div
            className={` ${
              isMobileView
                ? "hidden"
                : "whoarewe-text roboto whoarewe-color-text mb-5"
            }`}
          >
            {data.description}
          </div>
          <div
            className={`whoarewe-title whoarewe-color-text my-md-5 ${
              isMobileView ? "mt-5 mb-3 px-3" : ""
            }`}
          >
            {data.title}
          </div>
          <div
            className={`${
              isMobileView
                ? "font-size-14 line-height-25 text-white p-3"
                : "whoarewe-desc who-white-color "
            }`}
          >
            {data.subDescription}
          </div>
        </div>
        <div className="reviewSection">
          <div className="approachContainer p-3">
            <div>
              <img
                src={five}
                alt="fifty"
                width={isMobileView ? "61" : "81"}
                height={isMobileView ? "54" : "71"}
              />
              <img
                className={isMobileView ? "ms-2" : "ms-4"}
                alt="plus"
                src={plus}
                width={isMobileView ? "24" : "32"}
                height={isMobileView ? "24" : "32"}
              />
              <div>
                <label
                  className={
                    isMobileView
                      ? "text-white pt-3 roboto line-height-30 font-size-12"
                      : "text-white pt-3 roboto line-height-35 font-size-24"
                  }
                >
                  {data.techExpert}
                </label>
              </div>
            </div>
            <div>
              <img
                src={threethousand}
                alt="threethousand"
                width={isMobileView ? "134" : "200"}
                height={isMobileView ? "50" : "70"}
              />
              <img
                className={isMobileView ? "ms-2" : "ms-4"}
                alt="plus"
                src={plus}
                width={isMobileView ? "24" : "32"}
                height={isMobileView ? "24" : "32"}
              />
              <div>
                <label
                  className={
                    isMobileView
                      ? "text-white pt-3 roboto line-height-30 font-size-12"
                      : "text-white pt-3 roboto line-height-35 font-size-24"
                  }
                >
                  {data.projectDelivered}
                </label>
              </div>
            </div>
          </div>

          <div className="approachContainer p-3">
            <div>
              <img
                className="pt-2"
                src={fiveteen}
                alt="fiveteen"
                width={isMobileView ? "61" : "81"}
                height={isMobileView ? "54" : "71"}
              />
              <img
                className={isMobileView ? "ms-2" : "ms-4"}
                src={plus}
                alt="plus"
                width={isMobileView ? "24" : "32"}
                height={isMobileView ? "24" : "32"}
              />
              <div>
                <label
                  className={
                    isMobileView
                      ? "text-white pt-3 roboto line-height-30 font-size-12"
                      : "text-white pt-3 roboto line-height-35 font-size-24"
                  }
                >
                  {data.yearsOfExperience}
                </label>
              </div>
            </div>

            <div>
              <img
                className="pt-2"
                src={onehundred}
                alt="onehundred"
                width={isMobileView ? "104" : "139"}
                height={isMobileView ? "54" : "71"}
              />
              <img
                className={isMobileView ? "ms-2" : "ms-4"}
                src={plus}
                alt="plus"
                width={isMobileView ? "24" : "32"}
                height={isMobileView ? "24" : "32"}
              />
              <div>
                <label
                  className={
                    isMobileView
                      ? "text-white pt-3 roboto line-height-30 font-size-12"
                      : "text-white pt-3 roboto line-height-35 font-size-24"
                  }
                >
                  {data.certifications}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whoarewe;
