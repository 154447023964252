import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import LazyLoadVideo from "./bgvideo";

const HeroSection = ({ data, isMobileView }) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <LazyLoadVideo />
      <div className="homeHeroContainer">
        <h1 className="homeHeroTitle">
          <p className="title1">{data.title1}</p>
          <p className="title2">{data.title2}</p>
        </h1>
        <div className="homeHeroDescription">
          <label>
            {data.description1}
            <span dangerouslySetInnerHTML={{ __html: data.description2 }} />
            <span dangerouslySetInnerHTML={{ __html: data.description3 }} />
            {data.description4}
            {data.description5}
          </label>
        </div>
        <div
          className={` ${
            isMobileView
              ? "opsBtnwrap d-flex justify-content-center p-0"
              : "opsBtnwrap d-flex justify-content-center p-0"
          }`}
        >
          <Link
            className={`opsBtnlink ${isMobileView ? "" : "heroHbtn"}`}
            to={`/contact-us/`}
          >
            <p>{data.herobutton}</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
