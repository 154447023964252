import * as React from "react";
import "../styles/index2.scss";
import "../styles/home.scss";
import "../styles/index.scss";
import "react-tabs/style/react-tabs.css";

import Layout from "../components/layoutnew";
import { graphql } from "gatsby";
import { useMemo } from "react";
import HeroSection from "../components/homepage/herosection";
import Whoarewe from "../components/homepage/whoarewe";
import ClientCompany from "../components/client_company";
import Review from "../components/homepage/review";
import ApproachBusiness from "./../components/homepage/approach_bussiness";
import SmallBlog from "../components/smallBlog";
import SEO from "../components/seo";
import { Solution } from "../components/newTechnologies/solution";
import { useState, useLayoutEffect } from "react";

const IndexPage = ({ data }) => {
  const [solution, setSolution] = useState("0");
  const getIndexData = (data) => {
    const values = data.allStrapiIndexpage.edges[0].node;
    return values;
  };
  const getServiceData = data.allStrapiNewService.edges[0].node;
  const getData = useMemo(() => getIndexData(data), [data]);

  const [isMobileView, setIsMobileView] = useState(false);
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768); // Change 768 as per your breakpoint
  };

  useLayoutEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <Layout
      slug={getData.slug}
      locale={getData.locale}
      isMobileView={isMobileView}
    >
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
        keywords={getData.metaKeywords}
      />
      <main>
        <HeroSection
          data={getData.herosection}
          locale={getData.locale}
          metaTitle={getData.metaTitle}
          metaDes={getData.metDes}
          setSolution={setSolution}
          isMobileView={isMobileView}
        />
        <Whoarewe data={getData.whoarewe} isMobileView={isMobileView} />

        <ClientCompany data={getData.customers} isMobileView={isMobileView} />
        <Review
          data={{
            testimonials: getData.testimonials,
            customers: getData.customers,
            btntext: getData.herosection.herobutton,
          }}
          locale={getData.locale}
          isMobileView={isMobileView}
        />
        <ApproachBusiness
          data={{
            ...getData.approach,
            btntext: getData.herosection.herobutton,
          }}
          locale={getData.locale}
          isMobileView={isMobileView}
        />
        <SmallBlog
          title={getServiceData.services_title}
          description={
            getServiceData.services_description.data.services_description
          }
          accordions={getServiceData.services_accordions?.titles}
          isMobileView={isMobileView}
          blogData={getData.smallBlogs}
          blogDescription={getData.smallBlogs.title}
        />
        <Solution
          solution={getData.solution}
          icons={getData.icons3}
          isMobileView={isMobileView}
        />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($locale: String!) {
    allStrapiBlog(filter: { locale: { eq: $locale } }, limit: 50) {
      edges {
        node {
          locale
          slug
          title
          time
          heading1
          preview {
            data {
              preview
            }
          }
          id
          Author {
            name
          }
          blogimg {
            ext
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          publishedAt(formatString: "DD MMMM YYYY")
        }
      }
    }
    allStrapiIndexpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          metaTitle
          metaDes
          metaKeywords
          herosection {
            title1
            title2
            description1
            description2
            description3
            description4
            description5
            herobutton
            experiencedTeamsLabel
            ExperiencedTeams {
              item1 {
                label
                slug
              }
              item2 {
                label
                slug
              }
              item3 {
                label
                slug
              }
              item4 {
                label
                slug
              }
              item5 {
                label
                slug
              }
            }
          }
          enquireNow {
            btn
            email
            title
          }
          whoarewe {
            title
            description
            projectDelivered
            subDescription
            yearsOfExperience
            certifications
            techExpert
          }
          customers {
            someCustomers
            customersQuote
          }
          smallBlogs {
            titles {
              title
              content {
                id
                display
                link_text
                link
                card_title
                description1
                description2
                is_icons_required
              }
            }
            description
            title
          }
          approach {
            approachTitle
            approachDescription
            approachBulletPoints {
              id
              title
            }
          }
          solutions {
            title
            heading1
            heading2
            solutionsList {
              features {
                name
                src
              }
              id
              solutionPartner
            }
            CTA {
              btn
              des
              heading
            }
          }
          partner {
            title
          }
          whitepaper {
            heading
            description
            boxTitle
            boxDescription
            btntext
            btnlink
          }
          testimonials {
            name
            content {
              data {
                content
              }
            }
            imageUrl
          }
          campaigns {
            title
            heading1
            heading2
            description
            sections {
              heading
              sn
              title
              info
              tabs
              tab1 {
                opt1
                opt1description
                opt2
                opt2description
                opt3
                opt3description
                pillars {
                  name
                  options
                }
              }
              tab2 {
                opt1
                opt2
                opt3
                opt4
              }
              tab3 {
                opt3
                opt4
                opt5
                opt5description
                opt4description
                opt3description
                opt2description
                opt1description
                opt1
                opt2
              }
            }
          }
        }
      }
    }

    allStrapiNewService(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          services_title
          services_description {
            data {
              services_description
            }
          }
          services_image {
            url
          }
          services_accordions {
            titles {
              title
              id
              content {
                card_title
                description1
                description2
                display
                id
                link_text
              }
            }
          }
          cloud_icons {
            url
          }
        }
      }
    }
  }
`;
